export const contentTypes = {
  invalid: 'CONTENT_TYPE_INVALID',
  textPlain: 'CONTENT_TYPE_TEXT_PLAIN',
  textHTML: 'CONTENT_TYPE_TEXT_HTML',
  videoHLS: 'CONTENT_TYPE_VIDEO_HLS',
};

export const CONTENT_STATUS = {
  STATUS_INVALID: 'STATUS_INVALID',
  STATUS_CREATED: 'STATUS_CREATED',
  STATUS_ON_TRANSCODING: 'STATUS_ON_TRANSCODING',
  STATUS_ERROR: 'STATUS_ERROR',
};
